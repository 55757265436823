import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import TraderDetails from "../Monthly";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const GameList = () => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [traderData, settraderData] = useState();

  const columns = [
    {
      name: 'Players', sort: true, wrap: true, selector: row => (<>{row?.players[0]?.username} <strong><br />vs<br /></strong> {row?.players[1]?.username}</>), grow: 0.5,
    },
    { name: "Game ", sort: true, wrap: true, selector: (row) => row?.tableId?.gameMode, grow: .5 },
    { name: "Bet", sort: true, wrap: true, selector: (row) => row?.tableId?.bet, grow: .5 },
    { name: "Total Bet", sort: true, wrap: true, selector: (row) => row?.tableId?.totalBet, grow: .5 },
    { name: "Winner", sort: true, wrap: true, selector: (row) => row?.players[0]?.username, grow: .5 },
    { name: "Win Coin", sort: true, wrap: true, selector: (row) => row?.tableId?.wonCoin, grow: .5 },
    { name: "Rake", sort: true, wrap: true, selector: (row) => row?.tableId?.rake, },
    { name: "Game Started Date", sort: true, selector: (row) => moment(row?.gameStartedDate).format("Do MMMM YYYY"), },
    { name: "Game Won Date", sort: true, selector: (row) => moment(row?.gameWonDate).format("Do MMMM YYYY"), },
  ];

  function searchObjects(e) {
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();

    const matchingObjects = allData?.filter((obj) => {
      return (
        obj?.tableId?.gameMode?.toString().toLowerCase().includes(searchTerm) ||
        obj?.tableId?.bet?.toString().toLowerCase().includes(searchTerm) ||
        obj?.tableId?.totalBet?.toString().toLowerCase().includes(searchTerm) ||
        obj?.tableId?.wonCoin?.toString().toLowerCase().includes(searchTerm) ||
        obj?.tableId?.rake?.toString().toLowerCase().includes(searchTerm) ||
        moment(obj?.gameStartedDate).format("Do MMMM YYYY").toLowerCase().includes(searchTerm) ||
        moment(obj?.gameWonDate).format("Do MMMM YYYY").toLowerCase().includes(searchTerm) ||
        obj?.players[0]?.username?.toString().toLowerCase().includes(searchTerm) ||
        obj?.players[1]?.username?.toString().toLowerCase().includes(searchTerm)
      );
    });

    setExportData(matchingObjects);
  }


  console.log(exportData, "exportData");


  useEffect(() => {
    handleGameData();
  }, []);

  const handleGameData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getGamesData().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.data.reverse());
          setallData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
      }
    });
  };
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filterDate = () => {
    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;
    const filteredData = allData?.filter((item) => {
      const createdAtDate = new Date(item.createdAt).getTime();
      return (
        (from === null || createdAtDate >= from) &&
        (to === null || createdAtDate <= to)
      );
    });
    setExportData(filteredData?.reverse());
  };

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Game List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6 ">
            <div className="card-header d-flex justify-content-between">
              <div className="filter_bar">
                <form className="row">

                  <div className="col-5">
                    <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Search...." name="search" onChange={searchObjects} />
                  </div>
                  <div className="col-3">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => {
                      setFromDate(e.target.value);
                    }} />
                  </div>
                  <div className="col-3">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo"
                      value={toDate} onChange={(e) => { setToDate(e.target.value); }} />
                  </div>
                  <div className="col-1">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="dropdown">
                <button className="btn btn-dark dropdown-toggle col-2" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp"                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <TraderDetails userId={userId} traderData={traderData} />
  );
};

export default GameList;
