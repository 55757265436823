import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const GameSetting = () => {
    const [referralCommission, setRefferalCommission] = useState("");
    const [minWithdrawal, setMinWithdrawal] = useState("");
    const [maxWithdrawal, setMaxWithdrawal] = useState("");
    const [perDayWithdrawal, setPerDayWithdrawal] = useState("");
    const [bonus, setBonus] = useState("");
    const [upiId, setUpiId] = useState("");
    const [onMaintennance, setOnMaintennance] = useState("");
    const [kycVerified, setKycVerified] = useState("");

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "RefrralCommission":
                setRefferalCommission(event.target.value);
                break;
            case "MinWithdrawal":
                setMinWithdrawal(event.target.value);
                break;
            case "MaxWithdrawal":
                setMaxWithdrawal(event.target.value);
                break;
            case "PerDayWithdrawal":
                setPerDayWithdrawal(event.target.value);
                break;
            case "Bonus":
                setBonus(event.target.value);
                break;
            case "UpiId":
                setUpiId(event.target.value);
                break;

            default:
        }
    }

    const resetInputChange = () => {
        setRefferalCommission("");
        setMinWithdrawal("");
        setMaxWithdrawal("");
        setPerDayWithdrawal("");
        setBonus("");
        setUpiId("");
    }
    const handleUpdate = async (referralCommission, minWithdrawal, maxWithdrawal, perDayWithdrawal, upiId, onMaintennance, kycVerified) => {
        await AuthService.updateGameSetting(referralCommission, minWithdrawal, maxWithdrawal, perDayWithdrawal, upiId, onMaintennance, kycVerified).then(async result => {
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                    resetInputChange();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        })
    }
    useEffect(() => {
        handleGameData();
    }, [])

    const handleGameData = async () => {
        try {
            const result = await AuthService.handleInputChange();
            if (result.success) {
                alertSuccessMessage(result?.message);
                setRefferalCommission(result?.settings?.referralCommission);
                setMinWithdrawal(result?.settings?.minWithdrawal);
                setMaxWithdrawal(result?.settings?.maxWithdrawal);
                setPerDayWithdrawal(result?.settings?.perDayWithdrawal);
                setBonus(result?.settings?.bonus);
                setUpiId(result?.settings?.upiId);
                setOnMaintennance(result?.settings?.maintenance);
                setKycVerified(result?.settings?.kycVerified);
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Game Setting
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row justify-content-center" >
                                    <div className="col-md-8 ">
                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName">Refferal Commission </label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="RefrralCommission" value={referralCommission} onChange={handleInputChange} />
                                            </div>
                                            <small> % of Bet Amount </small>
                                        </div>

                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName"> Min Withdrawal </label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="MinWithdrawal" value={minWithdrawal} onChange={handleInputChange} />
                                            </div>
                                            <small> % of Bet Amount </small>
                                        </div>

                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName">Max Withdrawal </label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="MaxWithdrawal" value={maxWithdrawal} onChange={handleInputChange} />
                                            </div>
                                            <small> % of Bet Amount </small>
                                        </div>

                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName">Per Day Withdrawal</label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="PerDayWithdrawal" value={perDayWithdrawal} onChange={handleInputChange} />
                                            </div>
                                            <small> % of Bet Amount </small>
                                        </div>

                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName">Bonus</label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="Bonus" value={bonus} onChange={handleInputChange} />
                                            </div>
                                            <small> % of Bet Amount </small>
                                        </div>


                                        {/* <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName">UPI ID</label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="UpiId" value={upiId} onChange={handleInputChange} />
                                            </div>
                                        </div> */}
                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName"> On Maintennance :</label>
                                            <div className="input-group" >
                                                <label class="switch">
                                                    <input type="checkbox" name="OnMaintennance" value={onMaintennance} onChange={() => setOnMaintennance(!onMaintennance)} />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3" >
                                            <label className="  mb-1 " for="inputFirstName"> Kyc Verification :</label>
                                            <div className="input-group" >
                                                <label class="switch">
                                                    <input type="checkbox" name="kycVerification" value={kycVerified} onChange={() => setKycVerified(!kycVerified)} />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleUpdate(referralCommission, minWithdrawal, maxWithdrawal, perDayWithdrawal, upiId, onMaintennance, kycVerified)} > Submit Details </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default GameSetting;