import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const BankUpiRequest = () => {
    const [exportData, setExportData] = useState([]);
    const [allData, setallData] = useState([]);

    const linkFollow = (row) => {
        return (
            <div>
                <button className="btn btn-success btn-sm  me-2" onClick={() => { handleBankStatus(row?._id, row?.verified === "Approved" ? "Rejected" : "Approved") }} >Approved</button>
                <button className="btn btn-danger btn-sm  me-2" onClick={() => { handleBankStatus(row?._id, row?.verified === "Rejected" ? "Approved" : "Rejected") }}  >Rejected</button>
            </div>
        );
    };

    const columns = [
        { name: "User ID", selector: row => row._id, wrap: true, },
        { name: "Beneficiary Name", sort: true, wrap: true, selector: row => row?.beneficaryName, },
        { name: "Account Number", sort: true, wrap: true, selector: row => row?.accountNumber, },
        { name: "IFSC", sort: true, wrap: true, selector: row => row.ifscCode, },
        { name: "Phone Number", sort: true, selector: row => row.mobileNumber, },
        { name: "Registration Date", wrap: true, sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY hh:mm A") },
        { name: "Action", selector: linkFollow, wrap: true, },
    ];

    function searchObjects(e) {
        const keysToSearch = ["_id", "beneficaryName", "accountNumber", "ifscCode", "mobileNumber", "createdAt", "verified",];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setExportData(matchingObjects);
    }

    useEffect(() => {
        BankPending();
    }, []);

    const handleBankStatus = async (_id, status) => {
        await AuthService.updateBankStatus(_id, status).then(
            async (result) => {
                if (result.success) {
                    try {
                        BankPending();
                        alertSuccessMessage(result.message);
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    alertErrorMessage(result.message);
                }
            }
        );
    };

    const BankPending = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.BankPending().then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setExportData(result?.banks);
                    setallData(result?.banks);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No Data Available");

            }
        });
    };

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const filterDate = () => {
        const filteredData = allData.filter((item) => {
            const createdAtDate = new Date(item.createdAt);
            return (
                (!fromDate || createdAtDate >= new Date(fromDate)) &&
                (!toDate || createdAtDate <= new Date(toDate))
            );
        });
        setExportData(filteredData?.reverse())
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-wave-square"></i>
                                        </div>
                                        Pending Bank/UPI List
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-6">
                        <div className="card-header d-flex justify-content-between p-2 ps-4 ">
                            <p className="mb-0" ><span style={{ color: "red" }}> TOTAL RECORD </span> <b className="text-green ms-1 "> {allData.length}</b></p>
                        </div>
                        <div className="card-header d-flex justify-content-between">
                            <div className="filter_bar">
                                <form className="row">
                                    <div className="col">
                                        <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Search..." name="search" onChange={searchObjects} />
                                    </div>
                                    <div className="col">
                                        <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Account/UPI/Name" name="search" onChange={searchObjects} />
                                    </div>
                                    <div className="col">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                                            id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                                            onChange={(e) => { setToDate(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                                            Search
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase columns={columns} data={exportData} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};

export default BankUpiRequest;
