import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ApprovedRequest = () => {
  const [exportData, setExportData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');


  useEffect(() => {
    handleApprovedTransaction();
  }, []);
  const handleApprovedTransaction = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getApprovedTransaction().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.trans.reverse());
          setAllData(result?.trans);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");

      }
    });
  };

  const columns = [
    { name: <div style={{ whiteSpace: 'revert' }}>User ID</div>, wrap: true, selector: row => row.userId, grow: .5, },
    { name: <div style={{ whiteSpace: 'revert' }}>Name</div>, sort: true, wrap: true, selector: row => row?.title, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Transaction Type </div>, sort: true, wrap: true, selector: row => row.transactionType, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Transaction Into </div>, wrap: true, sort: true, selector: row => row.transactionInto, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Amount</div>, sort: true, wrap: true, selector: row => row.amount, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Description</div>, sort: true, wrap: true, selector: row => row.description, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Date</div>, wrap: true, sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), grow: .5 },
    { name: "Status", selector: row => row.status, wrap: true, grow: .5 },

  ];
  function searchObjects(e) {
    const keysToSearch = ["userId", "title", "transactionType", "transactionInto", "amount", "description", "createdAt", "status"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData?.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setExportData(matchingObjects);
  }
  const generatePDF = (allData) => {
    const doc = new jsPDF();
    const tableData = Array.isArray(allData) ? allData.map((row) => {
      return [
        moment(row.createdAt).format("Do MMMM YYYY"),
        row?.title,
        row?.transactionInto,
        row?.document_number,
        row?.amount,
        row?.currentCashBonus,
        row?.currentTotalBalance,
        row?.currentWinningBalance,
        row?.description,
        row?.previousTotalBalance,
        row?.previousWinningBalance,
      ];
    }) : [];

    doc.autoTable({
      head: [['createdAt', 'transactionInto', 'document_number', 'amount', 'currentCashBonus', 'previousTotalBalance', 'previousWinningBalance']],
      body: tableData,
    });

    doc.save("approved_kyc_list.pdf");
  };

  const handleGeneratePDF = () => {
    generatePDF(allData);
  };
  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse())
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Approved Withdrawal List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6 ">
            <div className="card-header d-flex justify-content-between p-0 ps-4 ">
              <p className="mb-0" ><span style={{ color: "red" }}> TOTAL RECORD </span> <b className="text-green ms-1 ">{allData.length}</b></p>
              <div className=" d-flex col-2">
                <button className="btn btn-danger btn-block w-100 d-flex" type="button" onClick={handleGeneratePDF}  >
                  Print PDF
                </button>
              </div>
            </div>
            <div className="card-header d-flex justify-content-between ps-4 p-3">
              <div className="filter_bar">
                <form className="row">
                  <div className="col-3">
                    <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Search..." name="search" onChange={searchObjects} />
                  </div>
                  <div className="col-2">
                    <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="User Name / Mobile Number" name="search" onChange={searchObjects} />
                  </div>
                  <div className=" col-2 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                      id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                  </div>
                  <div className=" col-2 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                      onChange={(e) => { setToDate(e.target.value); }} />
                  </div>
                  <div className=" col-2 col ">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="dropdown">
                <button className="btn btn-dark  dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <i className="fa fa-download me-3"></i> Export</button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
};
export default ApprovedRequest;
