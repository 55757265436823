import React, { useState } from "react";
import TraderDetails from "../Monthly";
const Maintenance = () => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [traderData, settraderData] = useState();

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse())
  }

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Update App Maintenance
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6">
            <div className="card-header d-flex justify-content-between">
              <div className="dropdown">
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%"></div>
              <div className="filter_bar">
                <form className="row">
                  <div className="mb-3 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                      id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                  </div><br />
                  <div className="mb-3 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                      onChange={(e) => { setToDate(e.target.value); }} />
                  </div>
                  <div className="mb-3 col ">
                    <div className="row">
                      <div className="col">
                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                          Search
                        </button>
                        <span>Status :</span> <input className="d-flex" type="checkbox" style={{ height: "10px", width: "10px" }} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <TraderDetails userId={userId} traderData={traderData} />
  );
};

export default Maintenance;
