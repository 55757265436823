import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import { CSVLink } from "react-csv";
import { $ } from 'react-jquery-plugin';
import DataTableBase from "../../../customComponent/DataTable";

const Banner = () => {
    const [selectBanner, setSelectBanner] = useState('');
    const [sequence, setSequence] = useState("");
    const [bannerFile, setBannerFile] = useState('');
    const [bannerList, setBannerList] = useState([]);
    const [editBanner, setEditBanner] = useState('');
    const [editSequence, setEditSequence] = useState('');
    const [editBannerFile, setEditBannerFile] = useState('');
    const [bannerId, setBannerId] = useState('');
    const [bannerSequence, setBannerSequence] = useState("");


    const handleChangeIdentity = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded, "fileUploaded");
        setBannerFile(fileUploaded);
    };

    console.log(bannerFile, "bannerFile");

    const resetInputChange = () => {
        setSelectBanner("");
        setSequence("");
        document.getElementById("bannerImg").value = "";
    };

    const url = `http://103.175.163.162:6090/uploads/${bannerFile.name}`;
    console.log(url, "url");

    const handleAddBanner = async (selectBanner, sequence, bannerFile, url) => {
        var formData = new FormData();
        formData.append('bannerType', selectBanner);
        formData.append('bannerSequence', sequence);
        formData.append('image', bannerFile);
        formData.append('url', url);
        const sequenceExists = bannerList.some(banner => parseInt(banner.banner_sequence) === parseInt(sequence));
        if (sequenceExists) {
            alertErrorMessage("Sequence number already exists.");
            return;
        }
        await AuthService.AddBanner(formData).then(async result => {
            if (result.message) {
                try {
                    alertSuccessMessage(result.message);
                    resetInputChange();
                    handleBanners();
                    setBannerSequence();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });
    };

    const linkFollow = (row) => {
        console.log(row, "row");
        return (
            <div>
                <button type="button" className="btn btn-sm btn-dark me-2" data-bs-toggle="modal" data-bs-target="#edit_banner" onClick={() => {
                    setBannerId(row?._id);
                }}>Edit</button>
            </div>
        );
    };

    const statuslinkFollow = (cell, row) => {
        return (
            <>
                <button type="button" className={cell?.isActive === true ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }}
                    onClick={() => handleStatus(cell?._id, cell?.isActive === true ? false : true)}>
                    {cell?.isActive === true ? "Active" : "Inactive"}
                </button>
            </>
        );
    };

    function imageFormatter(cell, row) {
        return (
            <>
                {/* <a href={ApiConfig?.appUrl + cell?.image} target="_blank" rel="noreferrer" >  */}
                <img className="table-img" src={ApiConfig?.appUrl + cell?.image} alt="Banner Img" />
                {/* </a> */}
            </>
        );
    }

    function BannerSqc(row) {
        return row?.bannerSequence === editSequence ? "Sqc is already exist" : row?.bannerSequence;
    }

    const columns = [
        { name: "Banner Type", shrink: true, selector: row => row?.bannerType, },
        { name: "Banner Sequence", shrink: true, selector: BannerSqc },
        { name: "Banner Image", selector: row => <img crossorigin='anonymous' className="table-img" src={ApiConfig?.appUrl + row?.image} alt="Banner Img" /> },
        { name: "Status", shrink: true, selector: statuslinkFollow, },
        { name: "Action", shrink: true, selector: linkFollow, wrap: true, },
    ];

    useEffect(() => {
        handleBanners();
    }, []);

    const handleBanners = async () => {
        LoaderHelper.loaderStatus(false);
        await AuthService.getBannerList().then(async result => {
            if (result.message) {
                LoaderHelper.loaderStatus(false);
                try {
                    setBannerList(result?.banners?.reverse());
                    alertSuccessMessage("Banner List Updated successfully");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No data found");
            }
        });
    };

    const handleEditBanner = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        setEditBannerFile(fileUploaded);
    };

    const resetEditInput = () => {
        setEditBanner("");
        setEditSequence("");
        document.getElementById("EditbannerImg").value = "";
    };

    const handleUpdateBanner = async (editBanner, editSequence, editBannerFile, bannerId) => {
        var formData = new FormData();
        formData.append('banner_type', editBanner);
        formData.append('banner_sequence', editSequence);
        formData.append('banner-image', editBannerFile);
        formData.append('_id', bannerId);
        await AuthService.updateBannerList(formData).then(async result => {
            if (result.success) {
                try {
                    alertSuccessMessage(result.message);
                    $('#edit_banner').modal('hide');
                    resetEditInput();
                    handleBanners();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });
    };

    const handleSequenceChange = (event) => {
        const inputSequence = parseInt(event.target.value);
        if (inputSequence !== 0) {
            setSequence(inputSequence);
        }
    };

    const handleStatus = async (bannerId, isActive) => {
        try {
            const result = await AuthService.handleBannerStatus(bannerId, isActive);
            if (result.success) {
                alertSuccessMessage(result.message);
                handleBanners();
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            alertErrorMessage("An error occurred while updating the banner status.");
        }
    };

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-image"></i></div>
                                            Banner Manangment
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-4">
                                <div className="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Banner Add/Edit</h5>
                                        </div>
                                        <form>
                                            <div className="mb-3 form-group">
                                                <label className="small mb-1" for="inputLocation">Banner Type </label>
                                                <select className="form-control form-control-solid form-select" id="exampleFormControlSelect1" name="selectBanner" value={selectBanner} onChange={(event) => setSelectBanner(event.target.value)}>
                                                    <option>Select Banner Type</option>
                                                    <option value="offerBanner">  Offer Banner </option>
                                                    <option value="invite">Invite</option>
                                                </select>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1">Banner Sequence</label>
                                                <input className="form-control  form-control-solid" type="number" placeholder="Enter Sequence" name="sequence" value={sequence} onChange={handleSequenceChange} min="1"></input>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1">Banner Image <small className="text-dark ms-1" >(600X400)</small> </label>
                                                <input className="form-control  form-control-solid" id="bannerImg" type="file" name="bannerFile" onChange={handleChangeIdentity}></input>
                                            </div>
                                            <button className="btn btn-indigo btn-block w-100 mt-2" type="button" onClick={() => handleAddBanner(selectBanner, sequence, bannerFile, url)} disabled={!selectBanner || !bannerFile}> Submit </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8" >
                                <div className="card">
                                    <div className="card-header">
                                        Banners List
                                        <div className="dropdown">
                                            <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                <CSVLink data={bannerList} className="dropdown-item">Export as CSV</CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <form className="row" >
                                            <div className="col-12" >
                                                <div className="table-responsive" >
                                                    <DataTableBase columns={columns} data={bannerList} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* Currency Pair modal data */}
            <div className="modal" id="edit_banner" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog  alert_modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">
                                Edit Banner
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3 form-group">
                                    <label className="small mb-1" for="inputLocation">Banner Type </label>
                                    <select className="form-control form-control-solid form-select" id="exampleFormControlSelect1" name="selectBanner" value={editBanner}
                                        onChange={(event) => setEditBanner(event.target.value)}>
                                        <option>Select Banner Type</option>
                                        <option value="offerBanner"> Offer Banner </option>
                                        <option value="invite">Invite</option>
                                    </select>
                                </div>
                                <div className="form-group  mb-3" >
                                    <label className="small mb-1">Banner Sequence</label>
                                    <input className="form-control  form-control-solid" type="text" placeholder="Enter Sequence" name="sequence" value={editSequence} onChange={(event) => setEditSequence(event.target.value)}></input>
                                </div>
                                <div className="form-group  mb-3" >
                                    <label className="small mb-1">Banner Image <small className="text-dark ms-1" >(600X400)</small> </label>
                                    <input className="form-control  form-control-solid" id="EditbannerImg" type="file" name="editBannerFile" onChange={handleEditBanner} ></input>
                                </div>
                                <button className="btn btn-indigo btn-block w-100 mt-2" type="button" onClick={() => handleUpdateBanner(editBanner, editSequence, editBannerFile, bannerId)}> Update Banner </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner;