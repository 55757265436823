import React from "react";

const Notice = () => {

    return (
        <div class="col-md-6 flex-fill">
            <div class="box box-danger" style={{width: "fit-content", minWidth: "50%"}}>
                <div class="box-body">
                    <table class="table table-bordered">
                        <tbody><tr>
                            <td>Description </td>
                            <td>

                                <textarea name="notice" id="notice" class="form-control" placeholder="Enter your notice here...."></textarea>
                            </td>
                        </tr>
                            <tr>
                                <td>Active</td>
                                <input type="checkbox" />
                               
                            </tr>
                            <input type="hidden" name="id" id="notice_id" value=""/>
                                <tr>
                                    <td>
                                        <button type="button" class="btn btn-indigo" onclick="saveSettings()">
                                            Submit
                                        </button>
                                    </td>
                                </tr>
                        </tbody></table>
                </div>
            </div>
        </div>
    )
}

export default Notice;