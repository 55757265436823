import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const PaymentSetting = () => {
  const [sgst, setSgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [tds, setTds] = useState("");
  const [withdrawCharge, setWithdrawCharge] = useState("");
  const [bonus, setBonus] = useState("");
  const [tdsPercentage, setTdsPercentage] = useState("");
  const [totalGstPercentage, setTotalGstPercentage] = useState("");
  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "SGST":
        setSgst(event.target.value);
        break;
      case "CGST":
        setCgst(event.target.value);
        break;
      case "TDS":
        setTds(event.target.value);
        break;
      case "WithdrawCharge":
        setWithdrawCharge(event.target.value);
        break;
      case "Bonus":
        setBonus(event.target.value);
        break;
      case "TDSPercentage":
        setTdsPercentage(event.target.value);
        break;
      case "TotalGSTPercentage":
        setTotalGstPercentage(event.target.value);
        break;
      default:
    }
  }

  const resetInputChange = () => {
    setSgst("");
    setCgst("");
    setTds("");
    setBonus("");
    setWithdrawCharge("");
    setTdsPercentage("");
    setTotalGstPercentage("");
  }
  const handleUpdate = async (sgst, cgst, tds, bonus, withdrawCharge, tdsPercentage, totalGstPercentage) => {
    await AuthService.updatePaymentSetting(sgst, cgst, tds, bonus, withdrawCharge, tdsPercentage, totalGstPercentage).then(async result => {
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          resetInputChange();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    })
  }
  useEffect(() => {
    handlePaymentData();
  }, [])

  const handlePaymentData = async () => {
    try {
      const result = await AuthService.handlePaymentSetting();
      if (result.success) {
        alertSuccessMessage(result?.message);
        setSgst(result?.data?.cgst);
        setCgst(result?.data?.sgst);
        setTds(result?.data?.tds);
        setBonus(result?.data?.bonus);
        setWithdrawCharge(result?.data?.withdrawCharge);
        setTdsPercentage(result?.data?.tdsPercentage);
        setTotalGstPercentage(result?.data?.totalGstPercentage);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error);
    }
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon"><i className="far fa-user"></i></div>
                    Payment Setting
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
            </div>
            <div className="card-body">
              <form>
                <div className="row justify-content-center" >
                  <div className="col-md-8 ">
                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">CGST </label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="CGST" value={cgst} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>

                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName"> SGST </label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="SGST" value={sgst} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>

                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">TDS </label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="TDS" value={tds} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>

                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">Withdrawal Charge</label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="WithdrawCharge" value={withdrawCharge} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>

                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">Bonus</label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="Bonus" value={bonus} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>


                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">Total TDS </label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="TDSPercentage" value={tdsPercentage} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>
                    <div className="form-group mb-3" >
                      <label className="  mb-1 " for="inputFirstName">Total GST </label>
                      <div className="input-group" >
                        <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="" name="TotalGSTPercentage" value={totalGstPercentage} onChange={handleInputChange} />
                      </div>
                      <small> % of Amount </small>
                    </div>
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={() => handleUpdate(cgst, sgst, tds, withdrawCharge, bonus, tdsPercentage, totalGstPercentage)} > Submit Details </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default PaymentSetting;