import React, { useState } from "react";


const AppContact = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('male');
    const [passwords, setPassword] = useState('');
    const [dob, setDob] = useState('');
    const [signId, setSignId] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("")


    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "gender":
                setGender(event.target.value);
                break;
            case "emailorphone":
                setSignId(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            case "confirmPassword":
                setConfirmPassword(event.target.value)
                break;
            case "dob":
                setDob(event.target.value);
                break;

            default:
        }
    }


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10" >
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        App Contact Setting
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputFirstName">First name <em>*</em></label>
                                        <input type="text" className="form-control  form-control-solid" id="inputFirstName" placeholder="Enter your first name" name="firstName" value={firstName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLastNames">Last name <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputBirthday">Gender <em>*</em></label>
                                        <select className="form-control form-control-solid" id="exampleFormControlSelect1" name="gender" value={gender} onChange={handleInputChange}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <label className="small mb-1" for="inputEmailAddress">Email or Phone Number</label>
                                    <input className="form-control form-control-solid" id="inputEmailAddress" placeholder="Enter your email address" name="emailorphone" value={signId} onChange={handleInputChange} />
                                </div>

                                <div className="row gx-3 mb-3">
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation">Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password" name="password" value={passwords} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation"> Confirm Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={confirmPassword} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation"> Confirm Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={confirmPassword} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation"> Confirm Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={confirmPassword} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation"> Confirm Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={confirmPassword} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-10">
                                        <label className="small mb-1" for="inputLocation"> Confirm Password</label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password Again" name="confirmPassword" value={confirmPassword} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <button className="btn btn-indigo" type="button" > Update </button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AppContact;