import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';
import AuthService from '../../../api/services/AuthService';
import DataTableBase from '../../../customComponent/DataTable';
import moment from 'moment';

const Deposit = () => {
  const [type, setType] = useState("all");
  const [allData, setAllData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [transaction, setTransaction] = useState([]);

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.depositTransactions();
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        console.log('Transaction Data:', result);
        const allData = result?.data || [];
        setTransaction(allData.map((transdata) => transdata.merchantTransactionId));
        setAllData(allData);
        setExportData(allData.reverse());
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error?.message);
    }
  };

  const handleCheckStatus = async (row) => {
    try {
      const result = await AuthService.checkPayStatus(row.merchantTransactionId);
      if (result?.success) {
        await getTransaction();
        alertSuccessMessage(result?.message);
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
  }

  const checkPayStatus = (row) => {
    return (
      <>
        {row?.status === "Pending" ? (
          <button className="btn btn-success btn-sm me-2" onClick={() => handleCheckStatus(row)}>
            Check Status
          </button>
        ) : (
          <span>{row?.status}</span>
        )}
      </>
    );
  };




  const columns = [
    { name: "Date", sort: true, wrap: true, selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"), grow: .5 },
    { name: "User Id", sort: true, wrap: true, selector: (row) => row?.userId },
    { name: "Amount", sort: true, wrap: true, selector: (row) => row?.amount },
    { name: <div style={{ whiteSpace: 'revert' }}>Current Total Balance</div>, sort: true, wrap: true, selector: (row) => row?.currentTotalBalance },
    { name: <div style={{ whiteSpace: 'revert' }}>Transaction Type</div>, sort: true, wrap: true, selector: (row) => row?.transactionType },
    { name: "Status", sort: true, wrap: true, selector: (row) => row?.status },
    { name: <div style={{ whiteSpace: 'revert' }}>Merchant Transaction Id</div>, sort: true, wrap: true, selector: (row) => row?.merchantTransactionId },
    { name: "Check Status", sort: true, wrap: true, selector: checkPayStatus },
  ];

  function searchObjects(e) {
    const keysToSearch = ["userId", "amount", "currentTotalBalance", "transactionId", "transactionType", "prize", "comission", "status", "merchantTransactionId"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter((obj) => {
      return keysToSearch.some((key) =>
        obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)
      );
    });
    setExportData(matchingObjects);
  }

  useEffect(() => {
    filterDate();
  }, [type, fromDate, toDate]);

  const filterDate = () => {
    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;

    const typeFilteredData = allData.filter((item) => {
      return type === "all" || item.transactionType === type;
    });

    const dateFilteredData = typeFilteredData.filter((item) => {
      const createdAtDate = new Date(item.createdAt).getTime();
      return (
        (from === null || createdAtDate >= from) &&
        (to === null || createdAtDate <= to)
      );
    });

    setExportData(dateFilteredData.reverse());
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="ri-arrow-up-down-line"></i>
                        Transaction History
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-body">
                <div className="filter_bar mb-4 mb-md-5">
                  <form className="form-inline search_form">
                    <div className="row gx-2 mt-2">
                      <div className="form-group mb-0 col-3">
                        <div className="input text">
                          <input
                            className="form-control form-control-solid h-auto"
                            id="inputLastName"
                            type="text"
                            placeholder="Search...."
                            name="search"
                            onChange={searchObjects}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <input
                          type="date"
                          className="form-control form-control-solid"
                          id="litepickerRangePlugin"
                          name="dateFrom"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="date"
                          className="form-control form-control-solid"
                          id="litepickerRangePlugin"
                          name="dateTo"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Deposit;
