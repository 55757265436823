import moment from "moment";
import React, { useEffect, useState } from "react";
import AuthService from "../../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../../customComponent/Loading/LoaderHelper";
import { CSVLink } from "react-csv";
import DataTableBase from "../../../../customComponent/DataTable";


const RejectWthRequest = () => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [traderData, settraderData] = useState();


  const columns = [
    { name: "User ID", selector: row => row.userId, wrap: true, grow: .5, },
    { name: "Name", sort: true, wrap: true, selector: row => row?.transactionInto, grow: .5, },
    { name: "Title", sort: true, wrap: true, selector: row => row.title, },
    { name: <div style={{ whiteSpace: 'revert' }}>Previous Winning Balance</div>, sort: true, selector: row => row.previousWinningBalance, grow: .5, },
    { name: <div style={{ whiteSpace: 'revert' }}>Previous Total Balance</div>, sort: true, selector: row => row.previousTotalBalance, grow: .5, },
    { name: <div style={{ whiteSpace: 'revert' }}>Current Winning Balance</div>, sort: true, selector: row => row.currentWinningBalance, grow: .5, },
    { name: <div style={{ whiteSpace: 'revert' }}>Current Total Balance </div>, sort: true, selector: row => row.currentWinningBalance, grow: .5, },
    { name: "Transation", sort: true, selector: row => row.transactionType, grow: .5, },
    { name: <div style={{ whiteSpace: 'revert' }}>Registration Date</div>, sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), grow: .5, wrap: true, },
    // { name: "Action", selector: linkFollow, wrap: true, grow: .5, },
  ];

  function searchObjects(e) {
    const keysToSearch = ["transactionInto", "userId", "title", "previousWinningBalance", "previousTotalBalance", "currentWinningBalance", "currentTotalBalance", "transactionType",];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setExportData(matchingObjects);
  }

  useEffect(() => {
    handleRejectedTransaction();
  }, []);

  const handleStatus = async (_id, status) => {
    await AuthService.updateStatus(_id, status).then(
      async (result) => {
        if (result.success) {
          try {
            handleRejectedTransaction();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleRejectedTransaction = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getRejectedTransaction().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.trans.reverse());
          setallData(result?.trans);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");

      }
    });
  };

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setExportData(allData)
  };

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Rejected/Declined Withdrawal List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6 ">
            <div className="card-header d-flex justify-content-between p-2 ps-4 ">
              <p className="mb-0" ><span style={{ color: "red" }}> TOTAL RECORD </span> <b className="text-green ms-1 "> {allData.length}</b></p>
            </div>
            <div className="card-header d-flex justify-content-between">
              <div className="filter_bar">
                <form className="row">
                  <div className="col-3">
                    <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Player ID" name="search" onChange={searchObjects} />
                  </div>
                  <div className="col-2">
                    <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="User Name / Mobile Number" name="search" onChange={searchObjects} />
                  </div>

                  <div className=" col-2 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                      id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                  </div>
                  <div className=" col-2 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                      onChange={(e) => { setToDate(e.target.value); }} />
                  </div>
                  <div className=" col-2 col ">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="dropdown">
                <button className="btn btn-dark  dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    "saaa");
};

export default RejectWthRequest;
