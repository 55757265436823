import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import jsPDF from 'jspdf';

const Daily = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  function imageFormatter(row) {
    return (
      <img style={{ width: "40%", height: "auto" }} className="table-img" src={ApiConfig?.appUrl + row?.user_selfie} alt="Selfie" />
    );
  };

  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
    { name: "ID", wrap: true, selector: row => row._id, },
    { name: "Name", wrap: true, selector: row => row?.first_name + " " + row.last_name },
    { name: "Email", wrap: true, selector: row => row.emailId, },
    { name: "Document Number", wrap: true, selector: row => row.document_number, },
    { name: "Pan Number", wrap: true, selector: row => row.pancard_number, },
    { name: "DOB", selector: row => row.dob, },
    { name: "Selfie", selector: row => imageFormatter(row), },
  ]
  function searchObjects(e) {
    const keysToSearch = ["_id", "first_name", "last_name", "emailId", "document_number", "pancard_number", "dob"];
    const searchTerm = e.target.value?.toLowerCase();
    const matchingObjects = allData.filter(obj => { return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)) });
    setData(matchingObjects);
  };

  useEffect(() => {
    handleData()
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getdataverifylist().then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.data) {
        try {
          setData(result.data.reverse());
          setAllData(result.data);
        } catch (error) {
          alertErrorMessage('No data found');
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage('No data found');
      }
    });
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon"> <i className="fa fa-user-check" ></i></div>
                    Daily Winner Leader Board
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              <div className="col-4 d-flex">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Date" name="search" onChange={searchObjects} />
                <div className="col-2 ">
                  <button className="btn btn-indigo btn-block w-100" type="button" >
                    Search
                  </button>
                </div>
              </div>

            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Daily;