import React, { useState } from 'react'
import AuthService from '../../../api/services/AuthService';
import { alertErrorMessage, alertSuccessMessage } from '../../../customComponent/CustomAlertMessage';


const ChangePassword = () => {

    const [inputData, setinputData] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    };


    const changepass = async (inputData) => {
        await AuthService.changepass(inputData).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    inputData({})
                } catch {
                    // alertErrorMessage(result?.message)
                }
            } else {
                alertErrorMessage(result?.message)
                console.log('Something went wrong');
            }
        })
    }

    return (
        <>
            <div className="col-10" id="layoutSidenav">
                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-lock-password-line"></i></div>
                                                Change Password
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div class="container-xl px-4 mt-n10">
                            <div class="card mb-4">
                                <form method="post" accept-charset="utf-8" novalidate="novalidate" id="changePassForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xs-6 col-sm-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input password">
                                                        <label for="old-password">Old Password <span class="required">*</span></label>
                                                        <input type="password" class="form-control" placeholder="Old Password" id="old-password" name="oldPass"
                                                            value={inputData.oldPass} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-6 col-sm-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input password">
                                                        <label for="password">New Password <span class="required">*</span></label>
                                                        <input type="password" class="form-control" placeholder="New Password" id="password" name="newPass"
                                                            value={inputData.newPass} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-primary submit" onClick={() => { changepass(inputData) }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default ChangePassword
