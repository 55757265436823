import React, { useEffect, useState } from "react";
import AuthService from "../../../../api/services/AuthService";
import { alertErrorMessage } from "../../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import DataTableBase from "../../../../customComponent/DataTable";

const OnlinePlayer = () => {
    const [tradingReport, setTradingReport] = useState([]);
    const [allData, setAllData] = useState([]);
    const columns = [
        { name: "Date/Time", selector: row => moment(row?.updatedAt).format("DD/MM/YYYY"), },
        { name: "Order Id", wrap: true, selector: row => row.order_id, },
        { name: "Currency", selector: row => row.main_currency, },
        { name: "Order Type", selector: row => row.order_type, },
        { name: "Fee", wrap: true, selector: row => parseFloat(row?.fee?.toFixed(8)) },
        { name: "Price", wrap: true, selector: row => row.price, },
        { name: "Quantity", wrap: true, selector: row => parseFloat(row?.quantity?.toFixed(8)), },
        { name: "Side", selector: row => row.side, },
        { name: "TDS", wrap: true, selector: row => row.tds, },
    ];

    useEffect(() => {
        handleTradingReport()
    }, []);

    const handleTradingReport = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.tradeHistory().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setTradingReport(result?.data.reverse());
                    setAllData(result?.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Online Player List
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Online Player List
                        </div>
                        <div className="table-responsive" width="100%">
                            <DataTableBase columns={columns} data={tradingReport} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default OnlinePlayer;