import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const CustomDataTable = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const customStyles = {
    rows: {
      style: {
        textAlign: "center",
      },
    },
  };

  return (
    <DataTable
      pagination
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationPerPage={rowsPerPage}
      onChangeRowsPerPage={handleRowsPerPageChange}
      direction="auto"
      responsive
      subHeaderAlign="right"
      subHeaderWrap
      striped
      highlightOnHover
      customStyles={customStyles}
      fixedHeader
      {...props}
    />
  );
};

export default CustomDataTable;
