import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const TableList = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [wonCoin, setWonCoin] = useState("");
  const [entryFees, setEntryFees] = useState("");
  const [rakePercentage, setRakePercentage] = useState("");
  const [totalBet, setTotalBet] = useState("");
  const [rake, setRake] = useState("");
  const [gameMode, setGameMode] = useState("");
  const [gameType, setGameType] = useState("2 Player");

  useEffect(() => {
    handleTableData();
  }, []);

  const handleTableData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getTables().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        try {
          setAllData(result?.data?.reverse());
          setData(result?.data);
          alertSuccessMessage(result?.message);
        } catch (error) {
          alertErrorMessage("No data found");
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No data found");
      }
    });
  };

  const linkFollow = (row) => {
    console.log(row, "row");
    return (
      <div>
        {row?.isActive === true ? (
          <button className="btn btn-success btn-sm  me-2" onClick={() => { handleStatus(row?._id, false); }}>
            Active
          </button>
        ) : (
          <button className="btn btn-danger btn-sm  me-2" onClick={() => { handleStatus(row?._id, true); }}>
            Inactive
          </button>
        )}
      </div>
    );
  };

  const columns = [
    { name: "Date", selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"), },
    { name: "Entry Fees ", wrap: true, selector: (row) => row.bet },
    { name: "Rake", wrap: true, selector: (row) => row?.rake },
    { name: "Rake Percentage", wrap: true, selector: (row) => row?.rakePercentage, },
    { name: "Total Bet", wrap: true, selector: (row) => row?.totalBet },
    { name: "Won Coin", wrap: true, selector: (row) => row?.wonCoin },
    { name: "Game Mode", wrap: true, selector: (row) => row?.gameMode },
    { name: "Game Type", wrap: true, selector: (row) => row?.gameType },
    { name: "Status", wrap: true, selector: linkFollow },
  ];

  function searchObjects(e) {
    const keysToSearch = ["bet", "rake", "rakePercentage", "totalBet", "wonCoin", "gameMode", "gameType",];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData?.filter((obj) => {
      return keysToSearch.some((key) =>
        obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)
      );
    });
    setData(matchingObjects);
  }

  const handleStatus = async (_id, status) => {
    await AuthService.updateTableStatus(_id, status).then(async (result) => {
      if (result.success) {
        try {
          handleTableData();
          alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "wonCoin":
        // setWonCoin(totalBet - rake);
        break;
      case "entryFees":
        console.log("entryFees");
        setEntryFees(value);
        let gameTyps = gameType === "2 Player" ? 2 : 4;
        console.log(gameTyps, "gameTyps");
        setTotalBet(value * gameTyps);
        setRakePercentage(0);
        setRake(0);
        break;
      case "rakePercentage":
        setRakePercentage(value);
        console.log(totalBet, "totalBet");
        console.log(value, "value");
        setRake((totalBet * value) / 100);
        setWonCoin(totalBet - (totalBet * value) / 100);
        break;
      case "totalBet":
        console.log("totalBet");
        setTotalBet(value);
        setWonCoin(value - (value * value) / 100);
        break;
      case "rake":
        setRake(value);
        break;
      case "gameMode":
        setGameMode(value);
        break;
      case "gameType":
        let gameTyp = value === "2 Player" ? 2 : 4;
        setGameType(value);
        setTotalBet(entryFees * gameTyp);
        setRake(0);
        setRakePercentage(0);
        setWonCoin(0);
        break;
      default:
        console.error("Unexpected input name:", name);
    }
  };

  const AddNewTable = async (entryFees, totalBet, rake, rakePercentage, wonCoin, gameMode, gameType) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.addNewTable(entryFees, totalBet, rake, rakePercentage, wonCoin, gameMode, gameType).then(async (result) => {
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          setGameMode("");
          setGameType("");
          alertSuccessMessage(result.message);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-user-check"></i>
                    </div>
                    Table List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              Table List
              <div className="col-4">
                <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Search..." name="search"
                  onChange={searchObjects} />
              </div>
              <div className="dropdown">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add_modal">
                  <i className="fas fa-plus me-2"></i> Add New</button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* add_modal */}
      <div
        class="modal fade"
        id="add_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="add_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="add_modalLabel">
                Add New Table
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row g-4 gx-md-5">
                <div class="col-md-6">
                  <label>
                    Won Coin <span className="text-danger">* </span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-solid"
                    name="wonCoin"
                    placeholder=" Enter Won Coin"
                    value={wonCoin}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
                <div class="col-md-6">
                  <labe>
                    Entry Fees <span className="text-danger">*</span>
                  </labe>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-solid"
                    name="entryFees"
                    placeholder="Enter Amount"
                    value={entryFees}
                    onChange={handleInputChange}
                  />
                </div>
                <div class="col-md-6">
                  <label>
                    Total Bet <span className="text-danger">* </span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-solid"
                    name="totalBet"
                    placeholder="Enter Total Bet Amount"
                    value={totalBet}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>

                <div class="col-md-6">
                  <label>
                    Rake Percentage <span className="text-danger">* </span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-solid"
                    name="rakePercentage"
                    placeholder="Enter Rake Percentage"
                    value={rakePercentage}
                    onChange={handleInputChange}
                  />
                </div>
                <div class="col-md-6">
                  <label>
                    Rake <span className="text-danger">* </span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control form-control-solid"
                    name="rake"
                    placeholder="Enter Rake"
                    value={rake}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
                <div class="col-md-6">
                  <label>
                    Game Mode <span className="text-danger">* </span>
                  </label>
                  <select
                    type="text"
                    className="form-control form-control-solid"
                    name="gameMode"
                    id=""
                    value={gameMode}
                    onChange={handleInputChange}
                  >
                    <option value=""> Select Game Mode </option>
                    <option value="Classic"> Classic </option>
                    <option value="Timer"> Timer </option>
                    <option value="Turbo"> Turbo </option>
                  </select>
                  {/* <input type="text" className="form-control form-control-solid" name="gameMode" placeholder="" value={gameMode} onChange={handleInputChange} /> */}
                </div>
                <div class="col-md-6">
                  <label>
                    Game Type <span className="text-danger">* </span>
                  </label>
                  <select
                    type="text"
                    className="form-control form-control-solid"
                    name="gameType"
                    id=""
                    value={gameType}
                    onChange={handleInputChange}
                  >
                    <option value="2 Player"> 2 Player </option>
                    <option value="4 Player"> 4 Player </option>
                  </select>
                  {/* <input type="text" className="form-control form-control-solid" name="gameType" placeholder="" value={gameType} onChange={handleInputChange} /> */}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-dark  px-5"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary px-5"
                data-bs-dismiss="modal"
                onClick={() =>
                  AddNewTable(
                    entryFees,
                    totalBet,
                    rake,
                    rakePercentage,
                    wonCoin,
                    gameMode,
                    gameType
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableList;
