import React, { useEffect, useState } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { Link } from "react-router-dom";
import moment from "moment";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import PanRequest from "../KycRequest/KycRequest";

const VerifyKyc = (props) => {
  const [showImage, setShowImage] = useState("");
  const [fullName, setFullName] = useState("");
  const [selfie, setSelfie] = useState("");
  const [mobile, setMobile] = useState("");
  const [panCardImage, setPanCardImage] = useState("");
  const [panCardNo, setPanCardNo] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [docType, setDocType] = useState("");
  const [docFrontImg, setDocFrontImg] = useState("");
  const [docBackImg, setDocBackImg] = useState("");
  const [date, setDate] = useState("");
  const [update, setUpdate] = useState("");
  const [birthday, setBirthday] = useState([]);
  const [rejectReason, setRejectReason] = useState("");
  const [activeScreen, setActiveScreen] = useState("pending");
  const [kycData, setkycData] = useState(props?.kycData);
  const [profileAvatar, setProfileAvatar] = useState({ avatar: "", bg: "" });

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "rejectReason":
        setRejectReason(event.target.value);
        break;
      default:
    }
  };

  useEffect(() => {
    handleData(props.userId);
  }, []);

  let profileBg = ["#95C11F", "#E94E1B", "#259DAB", "#F59D16", "#EB5BA0", "#A74FC6", "#D99BC4", "#FFFFFF", "#28EE6B", "#7CE7E7", "#727DE1", "#F9428F"]

  const handleData = async (userId) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.getkycdata(userId)
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setProfileAvatar({
          bg: result?.userKyc?.user?.backdropIndex,
          avatar: result?.userKyc?.user?.profilePhotoIndex,
        })
        setFullName(result.userKyc?.user?.username);
        setSelfie(result?.userKyc?.kyc?.documentImage);
        setMobile(result?.userKyc?.user?.mobileNumber);
        setUpdate(result?.userKyc?.user?.updatedAt);
        setBirthday(result?.userKyc?.kyc?.dob);
        setDocType(result?.userKyc?.kyc?.documentType);
        setPanCardImage(result?.userKyc?.user?.pancard_image);
        setPanCardNo(result?.userKyc?.user?.pancard_number);
        setDocNumber(result?.userKyc?.kyc?.documentNumber);
        setDocFrontImg(result?.userKyc?.kyc?.documentImage);
        setDocBackImg(result?.userKyc?.kyc?.documentImage);
        setDate(result?.userKyc?.user?.updatedAt);
      }
    } catch (error) { LoaderHelper.loaderStatus(false) }
  };

  const verifyIdentity = async (id, status, reason) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.getverifyidentity(id, status, reason)
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        $("#rejectmodal").modal("hide");
        setActiveScreen("detail");
      } else alertErrorMessage(result.message);
    } catch (error) { LoaderHelper.loaderStatus(false); }
  };

  const handleImageDetail = (img) => {
    setShowImage(img);
  };



  return activeScreen === "pending" ? (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <Link
                          to=""
                          className="page-header-icon"
                          onClick={() => setActiveScreen("support")}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                        {fullName}
                        {console.log(fullName, "fullname")}
                      </h1>
                    </div>
                    <div className="col-auto mt-4">
                      <div className="row">
                        <div className="d-flex">
                          <button
                            className="btn btn-danger btn-block"
                            data-bs-toggle="modal"
                            data-bs-target="#rejectmodal"
                            type="button"
                          >
                            Reject
                          </button>
                          <button
                            className="btn mx-2 btn-success btn-block"
                            type="button"
                            onClick={() => {
                              verifyIdentity(props?.userId, "Approved");
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="container-xl px-4 mt-n10">
              <div className="row">
                <div className="col-xl-4 mb-4">
                  <div className="card mb-4 mb-xl-0">
                    <div className="card-body py-5 pb-0">
                      <div className="text-center ">
                        <img
                          className="img-account-profile mb-4"
                          style={{ background: profileBg[profileAvatar?.bg], borderRadius: '50%' }}
                          src={`/assets/img/avatar/${profileAvatar?.avatar}.png`}
                          alt=""
                        />
                        <h3 className="fw-bolder fs-2 mb-0">{fullName}</h3>
                      </div>
                      <div className="doc_img py-5 px-4 my-4">
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Full Name:
                          </label>
                          <div className="col-lg-7">
                            <span className="fw-bolder fs-6 text-dark">
                              {fullName}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Mobile Number:
                          </label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">
                              {mobile}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Document Type:
                          </label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">
                              {docType ? docType : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Document Number:
                          </label>
                          <div className="col-lg-7 fv-row">
                            <span className="fw-bold fs-6 text-dark">
                              {docNumber ? docNumber : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Date of Birth:
                          </label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark">
                              {birthday
                                ? moment(birthday).format("Do MMMM YYYY")
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-lg-5 fw-bold text-muted">
                            Registration Date:
                          </label>
                          <div className="col-lg-7">
                            <span className="fw-bold fs-6 text-dark text-hover-primary">
                              {moment(date).format("Do MMMM YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-header-actions mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12  mb-3">
                          <div className="doc_img">
                            <div className="row mb-3">
                              <div className="col">
                                {docType} <small> (Document Image) </small>
                              </div>
                            </div>
                            <div className="ratio ratio-21x9">
                              <img crossOrigin="anonymous"
                                src={ApiConfig?.appUrl + docFrontImg}
                                alt=""
                                className="w-100 cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#ImageModal"
                                onClick={() => handleImageDetail(ApiConfig?.appUrl + docFrontImg)} />
                            </div>
                          </div>
                        </div>
                        <div className="doc_img">
                          <div className="row mb-3">
                            <div className="col">
                              {docType} No. : {docNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>
      <div
        className="modal"
        id="rejectmodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered alert_modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Are You Sure
                <b>{fullName}</b>
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows="7"
                    placeholder="Enter your message.."
                    value={rejectReason}
                    name="rejectReason"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <button className="btn btn-danger btn-block w-100" type="button" disabled={!rejectReason} onClick={() => {
                  verifyIdentity(props?.userId, "Rejected", rejectReason);
                }}>
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Image Detail */}
      <div className="modal image_modal" id="ImageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-lg" role="document">
          <div className="modal-content">
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="ratio ratio-16x9">
              <img crossOrigin="anonymous" src={showImage} className="w-100 cc_modal_img " alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Image Detail  */}
    </>
  ) : (
    <PanRequest />
  );
};

export default VerifyKyc;
