import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import VerifyKyc from "../VerifyKyc";
import { CSVLink } from "react-csv";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";

const KycRequest = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [activeScreen, setActiveScreen] = useState("pending");
    const [userId, setUserId] = useState("");
    const [userKycData, setuserKycData] = useState();

    const linkFollow = (row) => {
        return (
            <button className="verifybtn btn btn-dark btn-sm" onClick={() => { setUserId(row?.userId); setuserKycData(row?.kycdata, "clicked"); setActiveScreen("detail") }}>
                View
            </button>
        );
    };

    function imageFormatter(row) {
        return (
            <img style={{ width: "40%", height: "auto" }} className="table-img" crossOrigin="anonymous" src={ApiConfig?.appUrl + row?.documentImage} alt="Selfie" />
        );
    };

    const columns = [
        { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, },
        { name: "ID", wrap: true, selector: row => row._id, },
        { name: "Name", selector: row => row.fullName, wrap: true },
        // { name: "Reject Reason", selector: row => row.rejectReason, wrap: true },
        { name: "Document Type", wrap: true, selector: row => row.documentType, },
        { name: <div style={{ whiteSpace: "revert" }}>Document Number</div>, wrap: true, selector: row => row.documentNumber, },
        { name: "Image", selector: imageFormatter, },
        { name: "DOB", wrap: true, selector: row => moment(row?.dob).format("Do MMMM YYYY"), },
        { name: "Action", selector: linkFollow, },
    ];

    function searchObjects(e) {
        const keysToSearch = ["_id", "fullName", "documentType", "documentNumber", "dob", "rejectReason"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setData(matchingObjects);
    }


    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getdata().then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setData(result?.kyc?.reverse());
                    setAllData(result?.kyc);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };

    return activeScreen === "pending" ? (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className=" fa fa-user-slash"></i>
                                        </div>
                                        Pending Kyc
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Pending Kyc List
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-dark btn-l dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    <i className="fa fa-download me-3"></i>Export
                                </button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                                    <CSVLink data={data} className="dropdown-item">
                                        Export as CSV
                                    </CSVLink>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase columns={columns} data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    ) : (
        <VerifyKyc userId={userId} kycData={userKycData} />
    );
};
export default KycRequest;
